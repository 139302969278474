import Routing from './index.vue';
export const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('./main')
    },
    {
        path: '/purchase/:method(transfer|fastpass|premium|logpass)?',
        name: 'purchase',
        component: () => import('./purchase')
    },
    {
        path: '/replenishment',
        name: 'replenishment',
        meta: {
            auth: true
        },
        component: () => import('./replenishment')
    },
    {
        path: '/profile/:successOrder(new-order)?',
        name: 'profile',
        meta: {
            auth: true
        },
        component: () => import('./profile')
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import('./faq'),
        meta: {
            auth: false
        }
    },
    {
        path: '/docs/:type(contacts|privacy-policy|terms-of-use)?',
        name: 'docs',
        component: () => import('./docs')
    },
    {
        path: '/vkauth',
        name: 'VKAuth',
        props: (route) => ({ query: route.query.q }),
        component: () => import('./vk-auth')
    },
    // Другие маршруты
    {
        path: '/payment',
        redirect: '/replenishment'
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        meta: {
            layout: 'empty'
        },
        component: () => import('./not-found')
    }
    // {
    //   path: '/:pathMatch(.*)*',
    //   redirect: to => {
    //     if (to.path.match(/\/(transfer|fastpass|logpass|premium)/)) {
    //       return '/purchase';
    //     } else {
    //       return '/';
    //     }
    //   }
    // }
];
export { Routing };
