import { ref } from "vue";
export function useCaptcha() {
    const captchaToken = ref('');
    const captchaShow = ref(false);
    const loading = ref(false);
    const openCaptcha = () => {
        loading.value = true;
        captchaShow.value = true;
    };
    const captchaCallback = async (token, cb) => {
        loading.value = true;
        captchaShow.value = true;
        captchaToken.value = token;
        await cb();
        loading.value = false;
        captchaShow.value = false;
    };
    return { captchaShow, captchaToken, loading, captchaCallback, openCaptcha };
}
