import axios from 'axios';
export const AXIOS_INSTANCE = axios.create({
    baseURL: 'https://live-sales.backend-tree.com/api/v1/'
});
export const getLiveSales = async () => {
    try {
        const response = await AXIOS_INSTANCE.get('/sales/fetch');
        return response.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }
};
