import { ref, watch } from 'vue';
import { useWindowSize } from '@vueuse/core';
export function useMobileDetection() {
    const { width } = useWindowSize();
    const isMobile = ref(width.value < 1000);
    const isTablet = ref(width.value < 1919);
    const isSmallTablet = ref(width.value < 1279);
    // Отслеживание изменений ширины окна
    watch(width, (newValue) => {
        isMobile.value = newValue < 1000;
        isTablet.value = newValue < 1919;
        isSmallTablet.value = newValue <= 1279;
    }, { immediate: true });
    return { isMobile, isTablet, isSmallTablet };
}
