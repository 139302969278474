import { AuthApi } from 'shared/api/auth';
import { LogpassApi } from 'shared/api/logpass';
import { PendingApi } from 'shared/api/pending';
import { LiveApi } from 'shared/api/live';
import { TopupsApi } from 'shared/api/topups';
import { RatesApi } from 'shared/api/rates';
export const API = {
    auth: AuthApi,
    logpass: LogpassApi,
    pending: PendingApi,
    live: LiveApi,
    topups: TopupsApi,
    rates: RatesApi
};
