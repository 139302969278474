import axios from 'axios';
export const AXIOS_INSTANCE = axios.create({
    baseURL: 'https://logpass.backend-tree.com/api/v1/'
});
export const areSalesAvailable = async (method) => {
    try {
        const response = await AXIOS_INSTANCE.get(`/orders/are_sales_available?method=${method}`);
        return response.data.are_sales_available;
    }
    catch (error) {
        console.error(error);
        return false;
    }
};
export const createOrder = async (data) => {
    const body = {
        username: data.username,
        cookie: null,
        is_quick: true,
        robux_amount: data.robuxAmount,
        method: 'logpass_exclusive',
        is_paid: data.isPaid
    };
    try {
        const response = await AXIOS_INSTANCE.post('/orders/create_order', body);
        const responseData = response.data;
        return responseData;
    }
    catch (error) {
        console.error(error);
        return null;
    }
};
export const isHasPremium = async (userId) => {
    try {
        const response = await AXIOS_INSTANCE.get(`/roblox/check_premium?user_id=${userId}`);
        return response.data.is_premium;
    }
    catch (error) {
        console.error(error);
        return false;
    }
};
export const searchUsers = async (nickname) => {
    try {
        const response = await AXIOS_INSTANCE.get(`/roblox/search_users?keyword=${nickname}`);
        return response.data.found_users;
    }
    catch (error) {
        console.error(error);
        return null;
    }
};
export const checkCookie = async (cookie) => {
    try {
        const response = await AXIOS_INSTANCE.get(`/roblox/check_cookie?cookie=${cookie}`);
        return {
            isValid: response.data.is_cookie_valid,
            userId: response.data.user_id
        };
    }
    catch (error) {
        console.error(error);
        return null;
    }
};
export const getUsernameById = async (userId) => {
    try {
        const response = await AXIOS_INSTANCE.get(`/roblox/get_username?user_id=${userId}`);
        return response.data.username;
    }
    catch (error) {
        console.error(error);
        return null;
    }
};
export const confirmConsumerInteraction = async (orderId, isPremiumOrder) => {
    try {
        const response = await AXIOS_INSTANCE.patch('/roblox/orders/confirm_consumer_interaction', { order_id: orderId, is_premium_order: isPremiumOrder });
        return response.data.is_confirmed;
    }
    catch (error) {
        console.error(error);
        return false;
    }
};
export const fetchLastOrders = async () => {
    try {
        const response = await AXIOS_INSTANCE.get('/orders/fetch_last_orders/robux');
        return response.data.orders;
    }
    catch (error) {
        console.error(error);
        return null;
    }
};
export const setQuickCode = async (orderId, quickCode, isPremiumOrder) => {
    try {
        await AXIOS_INSTANCE.patch('/roblox/orders/set_quick_code', { order_id: orderId, quick_code: quickCode, is_premium_order: isPremiumOrder });
        return true;
    }
    catch (error) {
        console.error(error);
        return false;
    }
};
export const fetchPromocode = async (code) => {
    try {
        const response = await AXIOS_INSTANCE.get(`/promocodes/fetch_one/${code}`);
        return response.data;
    }
    catch (error) {
        console.error(error);
        return null;
    }
};
