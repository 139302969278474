import axios from 'axios';
export const AXIOS_INSTANCE = axios.create({
    baseURL: 'https://rates.backend-tree.com/'
});
export const getRates = async (userId) => {
    try {
        userId = userId || 'default';
        const response = await AXIOS_INSTANCE.get(`/get_rates/${userId}`);
        return response.data.before.rate;
    }
    catch (error) {
        return 1;
    }
};
