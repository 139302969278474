import { createApp } from 'vue';
import { router } from './providers';
import App from './index.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueCookies from 'vue-cookies';
import { createPinia } from 'pinia';
import InlineSvg from 'vue-inline-svg';
import { createHead } from '@unhead/vue';
const head = createHead();
const pinia = createPinia();
export const app = createApp(App).use(router).use(pinia).use(head).use(VueAxios, axios).use(VueCookies, { expires: '7d' });
app.component('inline-svg', InlineSvg);
