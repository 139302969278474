import { defineStore } from 'pinia';
const DEFAULT_DELAY = 6000;
export const useNotificationStore = defineStore('notification', {
    state: () => ({
        notifications: []
    }),
    actions: {
        setNotification({ type, title, description, delay = DEFAULT_DELAY }) {
            const id = this.notifications.length + 1;
            this.notifications.push({ id, type, title, description, delay });
            setTimeout(() => {
                this.resetNotification(id);
            }, delay);
        },
        resetNotification(id) {
            this.notifications = this.notifications.filter(notification => notification.id !== id);
        }
    }
});
