import axios from 'axios';
export const AXIOS_INSTANCE = axios.create({
    baseURL: 'https://topups.backend-tree.com/api/v1'
});
export const getCommissions = async () => {
    try {
        const response = await AXIOS_INSTANCE.get('/config/commissions');
        return response.data;
    }
    catch (error) {
        return [];
    }
};
export const createTopup = async (userId, amount, paymentType, paymentSystem, orderId, email) => {
    try {
        const orderIdFormatted = orderId ? { order_id: orderId } : {};
        const emailFormatted = email ? { email } : {};
        const response = await AXIOS_INSTANCE.post('/topups/create', {
            user_id: userId,
            rubble_amount: amount,
            payment_type: paymentType,
            payment_system: paymentSystem,
            ...orderIdFormatted,
            ...emailFormatted
        });
        if (!response.data) {
            throw new Error('Failed to create topup');
        }
        const data = response.data;
        return data;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};
